import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ResponseComponent } from './response/response.component';
import { GiftsComponent } from './gifts/gifts.component';
import { UsefulInfoComponent } from './useful-info/useful-info.component';
import { LodgingComponent } from './lodging/lodging.component';
import { ThankyouComponent } from './thankyou/thankyou.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'response', component: ResponseComponent },
  { path: 'gifts', component: GiftsComponent },
  { path: 'useful-info', component: UsefulInfoComponent },
  { path: 'lodging', component: LodgingComponent },
  { path: 'thankyou', component: ThankyouComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
