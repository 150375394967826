import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lodging',
  templateUrl: './lodging.component.html',
  styleUrls: ['./lodging.component.scss']
})
export class LodgingComponent implements OnInit {

  constructor() { }

  public lodgings: lodging[];

  ngOnInit() {
    this.lodgings = [
      {
        name: 'Logis Hotel le Chene doré',
        adress1: '28240 La Loupe',
        price: 'Entre 70 et 80€',
        tel: '02 37 81 06 71',
        site: 'https://www.logishotels.com/fr/hotel/logis-hotel-le-chene-dore-157101',
        img: 'chenedore.png'
      }, { name: 'Auberge de l’abbaye', adress1: '15 rue du commerce', adress2: '28482 Thiron-Gardais', tel: '237370404', site: 'http://www.aubergedelabbaye.fr/', img: 'abbaye.jpg', },
      { name: 'Auberge du moulin à vent', adress1: '23 chemin du moulin à vent', adress2: '28240 Manou', tel: '237818548', site: 'http://aubergedumoulinavent.com/', img: 'moulin.jpg', },
      { name: 'Brit Hôtel confort du Perche', adress1: 'Rue de la Bruyère', adress2: '28400 Nogent-Le-Rotrou', tel: '237534360', site: 'https://hotel-nogent-le-rotrou.brithotel.fr/', img: 'britconfort.jpg', price: '80 €', },
      { name: 'Chambre d’hôte la Loupe la longère du Perche', adress1: '7 chemin de la bogasserie', adress2: '28240 Manou', tel: '612401222', site: 'http://la-longere-du-perche.e-monsite.com/', img: 'loupe.jpg', },
      { name: 'Côté parc', adress1: '11 place du Général de Gaulle', adress2: '61110 Rémalard', tel: '233830251', site: 'http://www.cote-parc.fr/', img: 'parc.jpg', },
      { name: 'Gîte de la Brétillière', adress1: '61290 Les Menus', tel: '613206587', site: 'https://la-bretilliere.fr/?utm_source=local&utm_medium=organic&utm_campaign=google_my_business', img: 'bretilliere.jpg', },
      { name: 'Gîte de la Gentillère', adress1: '28400 Arcisses', tel: '237526765', site: 'https://www.facebook.com/lagentilliere', img: 'gentillere.jpg', },
      { name: 'Hôtel le lion d’or', adress1: '28 place saint Pol', adress2: '28400 Nogent-le-Rotrou', tel: '237520160', site: 'https://www.hotelauliondor.fr/', img: 'lion.jpg', price: '70 €', },
      { name: 'L’orée du Perche', adress1: '16 rue de laborde', adress2: '28340 La Ferté-Vidame', tel: '237375527', img: 'perche.jpg', },
      { name: 'La Glâtine', adress1: 'Lieu dit Glatigny', adress2: '28480 Saintigny', tel: '677081604', site: 'https://www.la-glatine.fr/', img: 'glatine.jpg', },
      { name: 'La longère aux volets rouges', adress1: '2 rue de la chapellerie', adress2: '28170 Maillebois', tel: '659711652', site: 'http://www.lalongereauxvoletsrouges.fr/', img: 'volets.jpg', },
      { name: 'La tour d’Aligre', adress1: '2 rue de la Loupe', adress2: '28240 Champrond-en-Gâtine', tel: '695425245', site: 'https://www.abritel.fr/location-vacances/p1285995', img: 'aligre.jpg', },
      { name: 'Le Logis auberge de la pomme de pin', adress1: '15 rue Michel Cauty', adress2: '28250 Senonches', tel: '237377662', site: 'http://www.restaurant-pommedepin.com/', img: 'pomme.jpg', },
      { name: 'Logis Hôtel la forêt', adress1: '22 rue de Verdun', adress2: '28250 Senonches', tel: '237377850', site: 'http://www.logishotels.com/fr/hotel/-2886?partid=661', img: 'foret.jpg', },
    ];
  }

}

export interface lodging {
  name: string;
  img: string;
  adress1: string;
  adress2?: string;
  price?: string;
  tel: string;
  site?: string;
}
